<template>
  <main id="main" class="reminder">
    <div class="wrap1160">
      <div class="remind-msg-comp">
        <p class="text-center" v-if="$store.state.loading">{{ $t('MEMBER_VERIFY_LOADING') }}</p>
        <div v-else>
          <p class="text-center" v-if="verifyResult">{{ verifyResult }}</p>
          <p v-if="errors && errors.message" class="text-center">{{errors.message}}</p>
          <div class="remind-comp-btn">
            <a class="btnBsc-Black" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t('REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL')}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
export default {
  name   : 'Verify',
  mixins : [CommonFilters],
  data() {
    return {
      errors       : {},
      verifyResult : null
    }
  },
  methods : {
    verifyMember(email, token) {
      const params = {
        email,
        token
      }
      this.$store.state.loading = true
      Methods.apiExcute('verify-member', this.$i18n.locale, params).then(data => {
        console.log('data: ', data)
        this.verifyResult = data.message
        this.$store.state.loading = false
      })
        .catch(error => {
          console.log('error: ', error)
          this.verifyResult = null
          this.$store.state.loading = false
          if (error && error.response && (error.response.status === 400 || error.response.status === 409)) {
            this.errors = Methods.parseHtmlResponseError(this.$router, error)
          } else {
            this.$cookies.remove('ss_id')
            this.$router.push('/login')
          }
        })
    },
  },
  created() {
    this.errors = {}
    this.verifyResult = null
    try {
      console.log(this.$route.query)
      if (this.$route.query) {
        console.log('Email: ', this.$route.query.email)
        console.log('Token: ', this.$route.query.token)
        const email = encodeURIComponent(this.$route.query.email)
        const token = encodeURIComponent(this.$route.query.token)
        this.verifyMember(email, token)
      }
    } catch (e) {
      console.log(e)
    }
  }
}
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
